<div class="quicklinks-container" *ngIf="(applicationQuery.isLoadingQuicklinks$ | async) === false">
  <div class="search-field" [@fadeInBy]="{value: '', params: {duration: 150}}"
    *ngIf="(applicationQuery.sideBarQuickLinks$ | async)?.length >= 15">
    <div class="input-container">
      <input type="text" [formControl]="searchFieldQuicklinks" placeholder="Filter Quick Links" maxlength="30" />
      <i class="fa fa-search"></i>
    </div>
  </div>
  <div class="quicklinks-wrapper" [@fadeInByStaggered]="{value: '', params: {duration: 150}}"
    *ngIf="(menuQuery.selectedTab$ | async) === menuTab.QuickLinks">
    <div class="quicklink staggered" *ngIf="languageService.enableLanguageSelection | async"
      (click)="clickLanguageSelector()">
      <div class="left-elements">
        <div class="icon">
          <i class="fa fa-language quicklink-icon" aria-hidden="true"></i>
        </div>
        <span class="quicklink-name" i18n>Language</span>
      </div>
      <div class="right-elements">
        <span class="quicklink-badge-icon">
          <i class="fa" [class.fa-angle-right]="(expandLanguageSelector$ | async) === false"
            [class.fa-angle-down]="expandLanguageSelector$ | async" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div class="language-selector-container"
      *ngIf="(languageService.enableLanguageSelection | async) && (expandLanguageSelector$ | async)"
      [@expandCollapseSimpler]>
      <app-language-selector>
      </app-language-selector>
    </div>
    <div
      *ngFor="let quicklink of (applicationQuery.sideBarQuickLinks$ | async) | filterByTermAndProperty: searchFieldQuicklinks.value:'title'; last as last; trackBy: quickLinksTrackBy"
      class="quicklink staggered" [class.locked]="quicklink.isLocked"
      [ngClass]="{last: last, disabled: !(quicklink?.linkURL || quicklink?.functionName)}"
      (click)="handleClick(quicklink.isLocked, quicklink.newTab, quicklink.linkURL, quicklink.functionName)">
      <div class="left-elements">
        <div class="icon">
          <i class="fa fa-{{ quicklink.icon }} quicklink-icon" aria-hidden="true"
            *ngIf="quicklink.isFontAwesomeIcon"></i>
          <i class="quicklink-icon"
            [appNavBarSvgLoader]="'images/quicklink-icons/black/quicklinks-' + quicklink.icon + '-blk'"
            *ngIf="!(quicklink.isFontAwesomeIcon)"></i>
          <span class="extra-icon"
            *ngIf="quicklink.iconSuffixText && quicklink.iconSuffixText.length > 0">{{quicklink.iconSuffixText}}</span>
        </div>
        <span class="quicklink-name">{{ quicklink.title }}</span>
      </div>
      <div class="right-elements">
        <span class="quicklink-badge-icon">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>
</div>
<app-loading-bar [show]="applicationQuery.isLoadingQuicklinks$ | async"></app-loading-bar>
